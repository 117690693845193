:root {
  --draw-color: black;
  --blank-color: white;
}
.line-cell {
  padding: 0;
  margin: 0;
  position: relative;
  width: 2px; /* Adjust width as needed */
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px; /* Adjust width as needed */
  height: 100%; /* Full height of the cell */
  background-color: var(--draw-color); /* Adjust color as needed */
}

.line::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px; /* Adjust width as needed */
  height: 71px; /* Adjust height as needed */
  background-color: var(--blank-color); /* Adjust color as needed */
}

.line::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2px; /* Adjust width as needed */
  height: 135px; /* Adjust height as needed */
  background-color: var(--blank-color); /* Adjust color as needed */
}

  .ServiceTopologyContainer {
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center the table horizontally */
    overflow-x: auto; /* Allows horizontal scrolling if content overflows */
    width: 100%; /* Ensures the container takes full width */
  }

  .ServiceTopologyTable {
    padding: 0;
    margin: 0;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures fixed layout */
  }

  .ServiceTopologyTableExpanded {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
  }
  
  .ServiceTopologyTableHeaderRow {
    padding: 0;
    margin: 0;
    height: 20px;
    white-space: nowrap; /* Prevents content wrapping */
  }
  
  .ServiceTopologyTableBodyRow {
    padding: 0;
    margin: 0;
    height: 90px;
    white-space: nowrap; /* Prevents content wrapping */
  }
  
  .ServiceTopologyTableFooterRow {
    padding: 0;
    margin: 0;
    height: 90px; /* Set a fixed height */
    white-space: nowrap; /* Prevents content wrapping */
  }
  
  .ServiceTopologyTableCell {
    padding: 0;
    margin: 0;
    white-space: nowrap; /* Prevents content wrapping */
    overflow: hidden; /* Ensures content does not overflow */
    text-overflow: ellipsis; /* Adds ellipsis for overflow content */
  }
  
  .ServiceTopologyFlexCenter {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .ServiceTopologyHrLine {
    border: 1px solid var(--draw-color);
    flex-grow: 1;
    margin: 0;
  }

  .MultiServiceTopologyHrLine {
    border: 1px solid var(--draw-color);
    flex-grow: 1;
    width: 20px;
    margin-top: -55px;
  }